import React from 'react'
import { useReducer, useEffect, useState } from 'react'
import { css } from 'emotion'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import Plain from 'slate-plain-serializer'
import isHotkey from 'is-hotkey'

import { performRequest } from './fetch'
import './index.css'

//

const reload = () => window.location.reload(true)

// https://facebook.github.io/create-react-app/docs/deployment#serving-apps-with-client-side-routing
function App(props)
{
  const [status, setStatus] = useState('')

  const [author, setAuthor] = useState(null)
  const [authors, setAuthors] = useState(null)
  const [styles, setStyles] = useState(null)
  const [fontsU, setFontsU] = useState([])
  const [fontsA, setFontsA] = useState([])

  // TODO: inject localstorage in initialState ?
  let initialState = {0: {childs: []}}
  const [script, dispatch] = useReducer(reducer, initialState, initReducer)

  const [active, setActive] = useState(null)
  const [menu, setMenu] = useState(false)
  const [editing, setEditing] = useState(false)
  const [selected, setSelected] = useState(null)
  const [input, setInput] = useState('')

  const [slateNode, setSlateNode] = useState(false)
  const [slateValue, setSlateValue] = useState(null)
  const [slatePlugins, setSlatePlugins] = useState([])
  const [slateSplit, setSlateSplit] = useState(false)

  // resize

  useEffect(() => {
    const resize = () =>
    {
      let windowWidth = window.innerWidth
      // let windowHeight = window.innerHeight

      // fluid typography: document body rounded font size for more consistent baseline grid
      // https://css-tricks.com/snippets/css/fluid-typography/
      // minFont + (maxFont - minFont) * ((100vw - minWidth) / (maxWidth - minWidth)))
      let fluidTypo = Math.floor(7+(21-7)*((windowWidth-320)/(3840-320))) * 2
      document.body.style.fontSize = fluidTypo + 'px'
    }

    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  // auth

  const authChange = (e) => {
    if (e.message === 'busy') {
      const { active } = e
      setStatus('busy')
      setActive(active)
    }
    if (e.message === 'logged') {
      const { secret, author, fonts } = e
      localStorage.setItem('solutions-author', secret)
      setAuthor(author)
      setFontsA(fonts)
      setStatus('logged')
    }
  }

  const logout = () => {
    const success = (response) => {
      localStorage.removeItem('solutions-author')
      reload()
    }
    performRequest('post', 'logout').then((response) => success(response))
  }

  useEffect(() => {
    const pass = localStorage.getItem('solutions-author')
    if (!pass) return setStatus('auth')
    const success = (response) => authChange(response.data)
    const error = (response) => setStatus('auth')
    performRequest('post', 'login', { pass: pass }).then((response) => success(response)).catch((response) => error(error.response))
    // reset localstorage after request to prevent being stuck at 'someone else is writing' screen
    localStorage.removeItem('solutions-author')
  }, [])

  // script

  // TODO: filter state to send backend only modified datas ? > with 'updated' / 'removed' status ?
  const save = () => {
    performRequest('post', 'save', { json: JSON.stringify(script) }).then((response) => reload())
  }

  useEffect(() => {
    const success = (response) => {
      const { authors, styles, fonts, script } = response.data

      setAuthors(authors)
      setStyles(styles)
      setFontsU(fonts)

      // if script is empty add a fresh first paragraph, else normalize backend datas and save it in the reducer
      const keys = Object.keys(script)
      const length = keys.length
      const next = Math.max(...keys) + 1
      if (length === 1) {
        dispatch({type: 'node_add', index: next, parent: 0, datas: {type: 'block'}})
        dispatch({type: 'child_add', index: 0, sibling: 0, child: next })
      } else {
        dispatch({type: 'reset', state: script})
      }
    }
    const error = (response) => console.log(response)
    performRequest('get', 'initial').then((response) => success(response)).catch((response) => error(error.response))
  }, [])

  // ui

  useEffect(() => {
    if (!menu) return
    // clear selection/slate when opening menu
    setSelected(null)
    slateReset()
  }, [menu])

  useEffect(() => {
    if (editing) return
    // clear selection/slate in reading mode
    setSelected(null)
    slateReset()
  }, [editing])

  //

  // title
  let title
  if (menu) title = <div className='fixed top left p-1 t-vertical noselect'><a href={process.env.PUBLIC_URL}>S O L U T I O N S</a></div>

  //

  // auth panels
  let auth
  if (status === 'auth') auth = <Auth callback={authChange}/>
  if (status === 'busy') auth = <p>{active} is writing something</p>

  //

  // admin panel
  // TODO: display save only if any difference
  // <p className='mb-1 noselect pointer' onClick={exportTxt}>Export .txt</p>
  // <p className='mb-1'><i>Archive</i></p>
  // <p className='t-nowrap t-ellipsis hidden' title='John Doe'>15/12/12</p>
  // <p className='t-nowrap t-ellipsis hidden' title='Jane Doe'>15/11/11</p>
  let controls
  if (status === 'logged') {
    controls =
      <>
        <div className='p-1 flex-100'>
          <p className='mb-1'><i>Font</i></p>
          <Fonts font={author.font} fonts={fontsA}/>
        </div>
        <div className='p-1'>
          <p className='mb-1 noselect pointer' onClick={save}>Save</p>
          <p className='mb-1 noselect pointer' onClick={logout}>Logout</p>
          <p className='mb-1'><i>Author: {author.name}</i></p>
        </div>
      </>
  } else {
    controls = <div className='p-1 flex-100'>{auth}</div>
  }
  const class1 = menu ? '' : ' none'
  const admin =
    <div className='fixed top right height-screen flex flex-row flex-nowrap above'>
      <div className='pointer noselect p-1' onClick={() => setMenu(!menu)}>{menu ? '→' : '←'}</div>
        <div className={'back-white bor-left flex flex-column' + class1} style={{width: '240px'}}>
          {controls}
        </div>
    </div>

  //

  // node selection
  const select = (o) => {
    // prevent select when editing with slate
    if (slateNode) return
    // close menu when selecting a node
    if (menu) setMenu(false)
    // https://stackoverflow.com/a/1144249/7662622
    const toggle = selected && JSON.stringify(selected) === JSON.stringify(o) ? null : o
    setSelected(toggle)
  }

  // script actions
  const scriptAction = (action) => {
    const type = action.index === 'empty' ? 'empty' : script[action.index].datas.type
    const parent = type === 'empty' ? action.parentIndex : script[action.index].parent
    const index = type === 'empty' ? 0 : action.index
    const keys = Object.keys(script)
    const length = script[0].childs.length
    const next = Math.max(...keys) + 1
    if (type === 'block') {
      if (action.type === 'add_block_before') {
        // check if input contains paragraphs (one by linebreak)
        let input = action.input
        input = input.split('\n')
        if (input.length) {
          for (let i = 0; i < input.length; i++) {
            let idx1 = next+i*2
            let idx2 = next+i*2+1
            //add empty paragraph
            dispatch({type: 'node_add', index: idx1, parent: parent, datas: {type: 'block'}})
            dispatch({type: 'child_add', index: parent, sibling: index, child: idx1, before: true})
            // if paragraph not empty, add inline
            if (input[i]) {
              dispatch({type: 'node_add', index: idx2, parent: idx1, datas: {type: 'inline', text: input[i], author: action.author}})
              dispatch({type: 'child_add', index: idx1, sibling: 0, child: idx2 })
            }
          }
        // if input is empty, create one empty paragraph
        } else {
          dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'block'}})
          dispatch({type: 'child_add', index: parent, sibling: index, child: next, before: true })
        }
      }
      if (action.type === 'add_block_after') {
        // check if input contains paragraphs (one by linebreak)
        let input = action.input
        input = input.split('\n')
        if (input.length) {
          input.reverse()
          for (let i = 0; i < input.length; i++) {
            let idx1 = next+i*2
            let idx2 = next+i*2+1
            //add empty paragraph
            dispatch({type: 'node_add', index: idx1, parent: parent, datas: {type: 'block'}})
            dispatch({type: 'child_add', index: parent, sibling: index, child: idx1})
            // if paragraph not empty, add inline
            if (input[i]) {
              dispatch({type: 'node_add', index: idx2, parent: idx1, datas: {type: 'inline', text: input[i], author: action.author}})
              dispatch({type: 'child_add', index: idx1, sibling: 0, child: idx2 })
            }
          }
        // if input is empty, create one empty paragraph
        } else {
          dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'block'}})
          dispatch({type: 'child_add', index: parent, sibling: index, child: next })
        }
      }
      if (action.type === 'remove_block') {
        // if removing last paragraph, add a fresh first one
        if (length === 1) {
          dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'block'}})
          dispatch({type: 'child_add', index: parent, sibling: index, child: next })
        }
        dispatch({type: 'child_remove', index: parent, child: index })
        dispatch({type: 'node_remove', index: index})
      }
    }
    if (type === 'inline') {
      const inline = script[action.index]
      if (action.type === 'add_inline_before' || action.type === 'add_inline_after' || action.type === 'update_inline') {
        // stop if input is empty
        if (action.input === '') return
      }
      if (action.type === 'edit_inline') {
        let value = slateInitialValue(inline.datas.text)
        const sameUser = inline.datas.author === author.id
        setSlateValue(value)
        setSlateNode(action.index)
        setSlatePlugins(sameUser ? [] : [Solutions()])
        setSlateSplit(false)
      }
      if (action.type === 'add_inline_before') {
        dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'inline', text: action.input, author: action.author}})
        dispatch({type: 'child_add', index: parent, sibling: index, child: next, before: true })
      }
      if (action.type === 'add_inline_after') {
        dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'inline', text: action.input, author: action.author}})
        dispatch({type: 'child_add', index: parent, sibling: index, child: next })
      }
      if (action.type === 'update_inline') {
        // stop if text is the same
        if (action.key === 'text' && action.value === script[index].datas.text) return
        dispatch({type: 'node_datas', index: index, key: action.key, value: action.value })
      }
      if (action.type === 'remove_inline') {
        dispatch({type: 'child_remove', index: parent, child: index })
        dispatch({type: 'node_remove', index: index})
      }
    }
    if (type === 'empty') {
      // stop if input is empty
      if (action.type === 'add_inline_after') if (action.input === '') return
      if (action.type === 'add_inline_after') {
        dispatch({type: 'node_add', index: next, parent: parent, datas: {type: 'inline', text: action.input, author: action.author}})
        dispatch({type: 'child_add', index: parent, sibling: index, child: next })
      }
    }
    // flush text input
    setInput('')
    // flush selection
    setSelected(null)
  }

  // slate actions
  const edit = (action) => {
    const index = slateNode
    if (action.type === 'confirm') {
      const content = Plain.serialize(slateValue)
      if (content !== '' && content !== script[index].datas.text) {
        scriptAction({type: 'update_inline', index: index, key: 'text', value: content})
      }
    }
    if (action.type === 'split') {
      // split text in two before an after the splittable char (space, ...)
      const text = script[index].datas.text
      const split1 = text.substring(0, slateSplit)
      const split2 = text.substring(slateSplit+1)
      scriptAction({type: 'update_inline', index: index, key: 'text', value: split1})
      scriptAction({type: 'add_inline_after', index: index, input: split2, author: script[index].datas.author})
    }
    if (action.type === 'unsplit') {
      let text = script[index].datas.text + ' ' + script[action.siblingIndex].datas.text
      scriptAction({type: 'update_inline', index: index, key: 'text', value: text})
      scriptAction({type: 'remove_inline', index: action.siblingIndex})
    }
    slateReset()
  }
  const slateChange = (o) => {
    const { document, selection } = o.value
    const { anchor, focus } = selection
    // check for a splittable spot, before a list of specific chars
    const chars = [' ']
    // const chars = [' ',',','.','!','?']
    let nextChar = document.getFragmentAtRange({anchor: anchor, focus: focus.moveForward(1)})
    if (chars.indexOf(nextChar.text) !== -1) setSlateSplit(anchor.offset)
    else setSlateSplit(false)

    if (o.value !== slateValue) setSlateValue(o.value)
  }
  const slateReset = () => {
    setSlateNode(false)
    setSlateValue(null)
    setSlatePlugins([])
    setSlateSplit(false)
  }

  //

  // script markup

  // texts from normalized datas
  // tree of paragraphs > inlines
  // paragraphs can be empty, not inlines
  // if there is not any inline inside paragraph, show an idle/empty one for interacting
  const c = (font) => `${font.src}\n.${font.author} { font-family: '${font.name}'; }`
  const text =
    <div className='width-70 max-width-reading width-sm center-auto p-1' style={{marginTop: '5em', marginBottom: '15em'}}>
      {!menu && status === 'logged' && <div className='fixed top left pointer noselect p-1 above' onClick={() => setEditing(!editing)}>{editing ? '⤵' : '⤴'}</div>}
      <div className={typeof fontsU !== 'undefined' && css(fontsU.map((f) => c(f)))}>
        {script[0].childs.map((p, i) => {
          const block = script[p]
          return (
            <Paragraph
              key={'p-'+i}
              datas={block.datas}
              index={p}
              editing={editing}
              callback={select}
              active={selected && selected.index === p}
              text={i+1}
            >
              {(block.childs.length === 0 && editing) &&
                <InlineEditable
                  click={() => select({index: 'empty', parentIndex: p})}
                  active={selected && selected.index === 'empty' && selected.parentIndex === p}
                >
                  <span className='noselect lightgrey'>{'...'}</span>
                </InlineEditable>
              }
              {block.childs.map((s, j) => {
                const inline = script[s]
                // editing > slate
                if (editing && s === slateNode) {
                  const sameUser = inline.datas.author === author.id
                  const sibling = j+1 < block.childs.length ? script[block.childs[j+1]] : false
                  return (
                    <div key={'s-'+j} className='width flex flex-align-x-center flex-wrap p-h mb-1 radius' style={{backgroundColor: 'rgb(252, 244, 218)'}}>
                      <Inline
                        author={inline.datas.author}
                        name={authors[inline.datas.author]}
                        index={s}
                        editing
                      >
                        <Editor
                          autoFocus={true}
                          autoCorrect={false}
                          spellCheck={false}
                          value={slateValue}
                          plugins={slatePlugins}
                          onChange={slateChange}
                        />
                      </Inline>
                      <div className='flex-100 flex flex-align-x-center flex-align-y-top flex-wrap pl-h pr-h'>
                        <Button callback={edit} action='cancel' text='Cancel'/>
                        {sameUser && <Button callback={edit} action={{type: 'confirm'}} text='Confirm'/>}
                        {(!sameUser && slateSplit) && <Button callback={edit} action={{type: 'split'}} text='Split'/>}
                        {(!sameUser && sibling && sibling.datas.author === inline.datas.author) && <Button callback={edit} action={{type: 'unsplit', siblingIndex: block.childs[j+1]}} text='Unsplit'/>}
                      </div>
                    </div>
                  )
                }
                return (
                  <Inline
                    key={'s-'+j}
                    style={styles[inline.datas.style]}
                    author={inline.datas.author}
                    name={authors[inline.datas.author]}
                    index={s}
                    editing={editing}
                    spaceAfter={j<block.childs.length-1}
                    active={selected && selected.index === s}
                    callback={select}
                  >
                    {inline.datas.text}
                  </Inline>
                )
              })}
            </Paragraph>
          )
        })}
      </div>
    </div>

  // actions markup

  let actions
  if (status === 'logged') {
    const inputChange = (e) => setInput(e.target.value)
    if (editing && selected) {
      const type = selected.index === 'empty' ? 'empty' : script[selected.index].datas.type
      let buttons
      if (type === 'empty') {
        buttons =
          <>
            <TextInput value={input} onChange={inputChange}/>
            <Button callback={scriptAction} action={{type: 'add_inline_after', index: selected.index, parentIndex: selected.parentIndex, input: input, author: author.id}} text='New'/>
          </>
      }
      if (type === 'inline') {
        // check if selected inline belongs to another user
        const inline = script[selected.index]
        const sameUser = inline.datas.author === author.id
        buttons =
          <>
            {sameUser && <Button callback={scriptAction} action={{type: 'remove_inline', index: selected.index}} text='Remove'/>}
            <TextInput value={input} onChange={inputChange}/>
            <Button callback={scriptAction} action={{type: 'add_inline_before', index: selected.index, input: input, author: author.id}} text='Before'/>
            <Button callback={scriptAction} action={{type: 'add_inline_after', index: selected.index, input: input, author: author.id}} text='After'/>
            <Button callback={scriptAction} action={{type: 'edit_inline', index: selected.index}} text='Edit'/>
            {sameUser &&
              <Select
                key={inline.datas.style}
                callback={scriptAction}
                action={{type: 'update_inline', index: selected.index, key: 'style'}}
                values={author.styles.map(s => ({key: s.id, value: s.name}))}
                value={inline.datas.style}
              />
            }
          </>
      }
      if (type === 'block') {
        // check if selected paragraph contains inlines belonging to another user
        const paragraph = script[selected.index]
        const sameUser = paragraph.childs.filter(i => script[i].datas.author !== author.id).length === 0
        buttons =
          <>
            <TextInput value={input} onChange={inputChange}/>
            <Button callback={scriptAction} action={{type: 'add_block_before', index: selected.index, input: input, author: author.id}} text='Before'/>
            <Button callback={scriptAction} action={{type: 'add_block_after', index: selected.index, input: input, author: author.id}} text='After'/>
            {sameUser && <Button callback={scriptAction} action={{type: 'remove_block', index: selected.index}} text='Remove'/>}
          </>
      }

      actions =
        <div className='fixed bottom width flex flex-align-x-center flex-align-y-top flex-wrap p-1 above back-white bor-top'>
          {buttons}
        </div>
    }
  }

  //

  // console.log('render')

  return (
    <>
      {text}
      {actions}
      {admin}
      {title}
    </>
  )
}

export default App

//

// script reducer / state as normalized tree
// new block : backend status > 'created'
// if block existed : backend status > 'removed'
// new inline : backend status > 'created'
// if inline existed : backend status > 'removed'
// if inline existed : backend status > 'updated'
// https://redux.js.org/basics/reducers
// https://redux.js.org/basics/reducers#designing-the-state-shape
// https://redux.js.org/recipes/structuring-reducers/refactoring-reducer-example
// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
// https://redux.js.org/faq/organizing-state#how-do-i-organize-nested-or-duplicate-data-in-my-state
// TODO: undo/redo capabilities, localstorage
const childs = (state, action) => {
  switch (action.type) {
    case 'child_add' : {
      // place new child after sibling
      let children = [...state, action.child]
      let index = state.indexOf(action.sibling)
      if (action.before) index -= 1
      children = arrayMove(children, children.length-1, index+1)
      return children
    }
    case 'child_remove' :
      return state.filter(i => i !== action.child)
    default:
      return state
  }
}
const node = (state, action) => {
  switch (action.type) {
    case 'node_datas' : {
      let datas = { ...state.datas, [action.key]: action.value }
      return { ...state, datas: datas }
    }
    case 'node_add' :
      return {
        parent: action.parent,
        childs: [],
        datas: action.datas
      }
    case 'child_add' :
    case 'child_remove' :
      return {
        ...state,
        childs: childs(state.childs, action)
      }
    default:
      return state
  }
}
const getChilds = (tree, id) => (
  tree[id].childs.reduce((acc, childId) => (
    [ ...acc, childId, ...getChilds(tree, childId) ]
  ), [])
)
const deleteChilds = (tree, ids) => {
  tree = { ...tree }
  ids.forEach(id => delete tree[id])
  return tree
}
function reducer(state, action)
{
  switch (action.type) {
    case 'reset':
      return initReducer(action.state)
    case 'node_remove' : {
      const childs = getChilds(state, action.index)
      return deleteChilds(state, [ action.index, ...childs ])
    }
    default : {
      return { ...state, [action.index]: node(state[action.index], action) }
    }
  }
  // console.log(state)
}

// https://stackoverflow.com/a/5306832/7662622
function arrayMove(arr, previousIndex, newIndex)
{
  const array = arr.slice(0)
  if (newIndex >= array.length) {
    let k = newIndex - array.length
    while (k-- + 1) array.push(undefined)
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  return array
}

// https://reactjs.org/docs/hooks-reference.html#lazy-initialization
function initReducer(state) { return state }

//

const Select = (props) => {
  const select = (e) => {
    let v = e.target.value
    props.callback({...props.action, value: v === 'empty' ? false : v})
  }
  const selects = props.values.map(v => <option key={v.value} value={v.key}>{v.value}</option>)
  return (
    <div
      className='back-white bor radius p-border noselect pointer shadow ml-h mr-h mb-h'
    >
      <select value={props.value ? props.value : 'empty'} onChange={select}>
        <option value='empty'>~</option>
        {selects}
      </select>
    </div>
  )
}

const Button = (props) =>
  <div
    onClick={() => props.callback(props.action)}
    className='back-white bor radius p-border noselect pointer shadow ml-h mr-h mb-h'
  >
    {props.text}
  </div>

//

const TextInput = (props) =>
  <div className='flex-50 flex-sm back-white bor radius p-border noselect pointer shadow ml-h mr-h mb-h'>
    <textarea
      className='width'
      type='text'
      placeholder='...'
      value={props.value}
      onChange={props.onChange}
    />
  </div>

//

const InlineEditable = (props) =>
  <span
    onClick={props.click}
    className={'back-white inline-block p-border mb-1 bor radius pointer relative'
    + (props.active ? ' shadow' : '')
    }
  >
    {props.children}
    {props.name &&
      <div className='width absolute bottom left pl-h pr-h small nofont lightgrey hidden t-ellipsis t-nowrap t-italic noselect' style={{marginBottom: '-1.6em'}}>
        {props.name}
      </div>
    }
  </span>

const Inline = (props) => {
  const select = () => props.callback && props.callback({type: props.type, index: props.index})
  let fontClass = 'author_' + props.author
  let style = props.style ? { color: props.style.color, fontSize: props.style.size+'em', lineHeight: '1.1' } : null
  let jsx = <span className={fontClass} style={style}>{props.children}</span>
  if (props.editing) jsx = <InlineEditable click={select} active={props.active} name={props.name}>{jsx}</InlineEditable>
  return (
    <>
      {jsx}
      {props.spaceAfter && <span className={'author_'+props.author}>&#x20;</span>}
    </>
  )
}

//

const ParagraphEditable = (props) =>
  <div className='bor-top relative' style={{paddingTop: '2em', paddingBottom: '2em'}}>
    {props.children}
    <div
      onClick={props.click}
      className={'absolute top back-white bor radius p-border noselect pointer above lightgrey' + (props.active ? ' shadow' : '')}
      style={{left: '50%', marginRight: '-50%', transform: 'translate(-50%, -55%)'}}
    >
      {props.title}
    </div>
  </div>

const Paragraph = (props) => {
  const select = () => props.callback({type: props.type, index: props.index})
  let jsx = <p className='width mt-1 mb-1'>{props.children}</p>
  if (props.editing) jsx = <ParagraphEditable click={select} active={props.active} title={props.text}>{props.children}</ParagraphEditable>
  return (
    jsx
  )
}

//

const Fonts = ({ font, fonts }) => {
  const select = (e) => {
    let v = e.target.value
    performRequest('post', 'font', { font: v }).then((response) => reload()).catch((response) => reload())
  }
  const selects = fonts.map(font => <option key={font.name} value={font.name}>{font.name}</option>)
  return (
    <>
      <select className='width' value={font === null ? 'empty' : font} onChange={select}>
        <option value='empty'>~</option>
        {selects}
      </select>
    </>
  )
}

//

const Auth = (props) => {
  const formInitial = { pass: '' }
  const [form, setForm] = useState(formInitial)
  const [message, setMessage] = useState('')
  const formChange = (e) => {
    const target = e.target
    const name = target.name
    const value = target.value
    setForm({ ...form, [name]: value })
  }
  const post = () =>
  {
    const success = (e) => props.callback(e.data)
    const error = (e) => setMessage('Not found')
    performRequest('post', 'login', form).then((response) => success(response)).catch((response) => error(response.response))
  }
  const submit = (e) => {
    e.preventDefault()
    post()
  }
  let warning = (message) ? <span style={{fontSize: '.5em'}}>{message}</span> : ''
  return (
    <form onSubmit={submit} style={{maxWidth: '100%'}}>
      {warning}
      <PasswordInput name='pass' label='Secret' value={form.password} onChange={formChange}/>
      <div className='width bor-bottom noselect pointer'>
        <input
          className='width t-center sm p-h'
          type='submit'
          value='Confirm'
        />
      </div>
    </form>
  )
}

//

const PasswordInput = (props) =>
  <div className='width pt-h pb-h bor-bottom flex'>
    <label className='flex-30' htmlFor={props.name}>{props.label}{props.warning && props.warning}</label>
    <input
      className='flex-70 pl-1 pr-1'
      name={props.name}
      type='password'
      autoComplete='password'
      value={props.value}
      onChange={props.onChange}
    />
  </div>

//

const slateInitialValue = (t) => Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: t
          },
        ],
      },
    ],
  },
})

// https://github.com/ianstormtaylor/slate
// https://docs.slatejs.org/guides/commands-and-queries
// https://docs.slatejs.org/guides/data-model
// https://docs.slatejs.org/slate-core/commands
// https://docs.slatejs.org/slate-core/value
// https://docs.slatejs.org/slate-core/selection
// https://docs.slatejs.org/slate-core/document
// https://docs.slatejs.org/slate-core/node
// https://docs.slatejs.org/slate-core/editor
// https://immutable-js.github.io/immutable-js/docs/#/
// https://docs.slatejs.org/slate-core/plugins
// https://docs.slatejs.org/slate-react/plugins
// https://github.com/ianstormtaylor/slate/blob/master/docs/general/plugins.md
// https://github.com/enzoferey/slate-instant-replace
const Solutions = (options) => ({
  onKeyDown(event, change, next) {

    // keys
    const key = event.key
    // console.log(key)

    // hotkeys & others
    if (isHotkey('mod+a', event)) return next()
    if (isHotkey('mod+r', event)) return next()
    if (isHotkey('mod+c', event)) return next()

    // keycodes
    if (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowUp' || key === 'ArrowDown') return next()

    // cancel other events
    event.preventDefault()
  }
})
