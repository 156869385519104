import axios from 'axios'

export function performRequest(method, url, params)
{
  let body = method === 'get' ? 'params' : 'data'

  const config = {
    method: method,
    headers: {'Content-Type': 'application/json'},
    baseURL: process.env.REACT_APP_API_URL,
    url: url,
    [body]: params,
  }

  return axios.request(config)
}
